import React, { Component } from 'react';
import { Dimmer, Modal, Loader, Segment } from 'semantic-ui-react';



import videojs from 'video.js';
import VREPlayer from 'videojs-react-enhanced';
import 'video.js/dist/video-js.css';


const videojsOptions = {
    fluid: true,
};

var authKey = '';
var serverAddress = '';
class Player extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feedReady: false,
            ref: React.createRef(),
            player: null,
            url: '',
            options: ''
        }

        this.cleanup = this.cleanup.bind(this);
    }
    componentDidMount(){
        var username = 'cmonzon@juganu.com';
        var password = '4micKRYi8yBTKry';
        var cameraId = this.props.feedUrl;
        var systemId = 'fe1d9cb5-0d6b-429b-b7ee-6aa06b60a2a2';
        var serverCloudAddress = "https://" + systemId + ".relay.vmsproxy.com";
        authKey = '';
        serverAddress = serverCloudAddress; 
       
    console.log(this.props)
        fetch(
            serverAddress + "/api/getNonce").then(response => {
                return response.json();
              }).then(response => {
                var realm = response.reply.realm;
                var nonce = response.reply.nonce;
                var digest = this.$md5(username + ":" + realm + ":" + password);
                var partial_ha2 = this.$md5("GET" + ":");
                var simplified_ha2 = this.$md5(digest + ":" + nonce + ":" + partial_ha2);
                 authKey = btoa(username + ":" + nonce + ":" + simplified_ha2);
                console.log("---->",serverAddress + '/hls/' + cameraId + '.m3u8?lo&auth=' + authKey)

                   
                

                
              }).catch(error => {
                 console.log(error)
              });
    }

    
    componentWillReceiveProps(nextProps) {

     console.log("nextProps",nextProps)
     this.setState({
        options: {
            src: serverAddress + '/hls/' + nextProps.feedUrl + '.m3u8?lo&auth=' + authKey,
            controls: true,
            autoplay: true,

        }
    });
    
    }

    cleanup = () => {
        let player = this.state.player;
        player.dispose();
        this.setState({ feedReady: false, player: null })
        this.props.closeLiveFeed();
    }



    render() {
        return (
            <Modal
                closeIcon={true}
                onClose={() => { this.cleanup() }}
                open={this.props.showLiveFeed}
                style={{ 'width': '1350px' }}>
                <Modal.Header>Live feed</Modal.Header>
                <Modal.Content>

                    <VREPlayer
                        playerOptions={this.state.options}
                        videojsOptions={videojsOptions}
                        onReady={(player) => {

                            this.setState({
                                player: player
                            })
                        }}
                        onPlay={(e, _, second) => console.log('Play!')}
                        onPause={(e, _, second) => console.log('Pause!')}
                        onEnded={(e, _) => console.log('Ended!')}
                    />



                </Modal.Content>
            </Modal>
        )
    }
}

export default Player;