import React, { Component } from 'react';
import { Grid, Header, Image } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';
import Marker from './components/marker';
import Player from './components/player';
import logo from './logo.png';

export default class App extends Component {
  constructor( props ) {
    super( props );

    this.state = { 
      map: '',
      markers: [
        { description: 'Americas 151/152', id: 1, lat: 19.400738, lng: -99.149529, feed1: `730373e3-482e-362f-6783-8a19c1c3720c`, feed2: `27d83da6-59de-0a9e-5bde-437d2101c181` },
        { description: 'Americas 171/172', id: 2, lat: 19.400558, lng: -99.149867, feed1: `63fd33f5-2c8d-de7a-af27-7cc850c1c1e5`, feed2: `f29eadf1-4b19-e9bd-1eba-32e0ce3bc1c6` },
        { description: 'Arboledas 111/112', id: 3, lat: 19.378886, lng: -99.161488, feed1: `f10055a5-1e89-6dd8-1929-c156b7e40d81`, feed2: `33546bec-71e2-f9da-03d3-ef5bf585a9e3` },
        { description: 'Arboledas 131/132', id: 4, lat: 19.379310, lng: -99.161172, feed1: `d60b6162-c276-3646-c02d-12a29c1c0091`, feed2: `979919f3-d6f0-daac-ea5f-3a535ff7e8a8` },
        { description: 'Arboledas 141/142', id: 5, lat: 19.379339, lng: -99.161818, feed1: `8bd7aa10-27ca-7e1f-76e3-60015233d638`, feed2: `874272ff-c8d0-44e5-ce29-592b3752ee89` },
        // { description: 'JRE6', id: 6, lat: 19.401051, lng: -99.148989, feed1: `${process.env.REACT_APP_STREAM_URL}/0645e215`, feed2: `${process.env.REACT_APP_STREAM_URL}/89fd4061` },
        { description: 'Arboledas 151/152', id: 7, lat: 19.378595, lng: -99.161552, feed1: `c3fdbf28-529c-77eb-872a-b0c884530d39`, feed2: `2917861a-d75b-2501-09ea-2a93acb936dd` },

        // { description: 'JRE8', id: 8, lat: 19.392384, lng: -99.182899, feed1: `${process.env.REACT_APP_STREAM_URL}/1ea10bee`, feed2: `${process.env.REACT_APP_STREAM_URL}/b4d03635` },
        { description: 'Arboledas 161/162', id: 9, lat: 19.379257, lng: -99.161472, feed1: `774366b3-b421-371c-ec69-991d5211abea`, feed2: `25cbcaf2-8faf-8a46-9712-e4fbd508c0bc` },
        { description: 'Hundido 111/112', id: 10, lat: 19.379917, lng: -99.177756, feed1: `824efe4e-b4ad-7036-e9e1-484e34fcc2bc`, feed2: `8e80f013-bf21-8131-03c5-0f3813b0f7f9` },
        { description: 'Hundido 121/122', id: 11, lat: 19.379890, lng: -99.177398, feed1: `c8b1b371-9b9e-f0f1-041e-c01291a592ba`, feed2: `a078aad9-c74d-c7a8-6f7e-bbbc8414a8e9` },
        { description: 'Hundido 131/132', id: 12, lat: 19.380022, lng: -99.177900, feed1: `ad912768-1734-a5c0-21f7-89e5a4b5da2e`, feed2: `22883577-f756-c95a-a6da-1b53c6e40d28` },
        { description: 'Hundido 141/142', id: 13, lat: 19.379603, lng: -99.178275, feed1: `4526a842-7508-bdc0-7f91-3bd99829eeae`, feed2: `ebc74378-ee57-44c1-55fa-596d9ae97b7d` },
        { description: 'Hundido 151/152', id: 14, lat: 19.379694, lng: -99.177939, feed1: `44e00e73-2d51-c436-7411-d3611bb1f562`, feed2: `53373854-a118-9667-4d92-d6aee4642840` },
		
        { description: 'Iztaccihuatl 121/122', id: 15, lat: 19.390446, lng: -99.135338, feed1: `c0ea60e9-e1f4-8715-4f1a-a929a9479cbf`, feed2: `a3e11729-45ea-53b8-e103-c8f69b7e5743` },
        { description: 'Iztaccihuatl 131/132', id: 16, lat: 19.390543, lng: -99.135090, feed1: `84cb689d-aea9-98a5-7a6a-18dbe674d167`, feed2: `2212b6a8-458e-85e9-389f-00a547714357` },
        { description: 'Iztaccihuatl 141/142', id: 17, lat: 19.390496, lng: -99.134744, feed1: `3f8d9fcf-6a1a-2a2b-dcf5-0cc28d6d5544`, feed2: `ae318006-8a43-80b2-5cf7-10a9b463d0ed` },
        { description: 'Iztaccihuatl 161/162', id: 18, lat: 19.389979, lng: -99.135397, feed1: `b327e1cd-a7ff-96cd-2d94-297c7d5192ea`, feed2: `fe4922c5-25da-8309-2315-49a57b1a051c` },
        { description: 'Iztaccihuatl 171/172', id: 19, lat: 19.390227, lng: -99.135368, feed1: `d7981b9a-7327-b452-d48a-68e7f79bd031`, feed2: `30dce76d-cf86-fa56-8109-57f6fcaafb18` },
        { description: 'Pombo 121/122', id: 20, lat: 19.391971, lng: -99.182993, feed1: `2aa5f89c-5b75-00db-1c22-ff2e1ee108ec`, feed2: `eeaedc55-d2d6-5f3b-3303-1be14f8f953a` },
        // { description: 'JRE21', id: 21, lat: 19.379107, lng: -99.160875, feed1: `${process.env.REACT_APP_STREAM_URL}/8297c876`, feed2: `${process.env.REACT_APP_STREAM_URL}/57d6b441` },
        
        { description: 'Pombo 132', id: 30, lat: 19.393018, lng: -99.183717, feed1: `a5896272-2699-0f7b-f2cf-492d1bd018a3`, feed2: `a5896272-2699-0f7b-f2cf-492d1bd018a3` },
        // { description: 'JRE23', id: 31, lat: 19.389725, lng: -99.135427, feed1: `${process.env.REACT_APP_STREAM_URL}/e9d5d047`, feed2: `${process.env.REACT_APP_STREAM_URL}/41fe96e2` },
        { description: 'Pombo 141/142', id: 32, lat: 19.392397, lng: -99.184175, feed1: `32627dbd-17d1-2c71-616e-c1cc7402142d`, feed2: `f9417eb6-0bc1-e057-9919-90f4b37b809c` },
        { description: 'Pombo 161/162', id: 33, lat: 19.392835, lng: -99.183546, feed1: `9eeae352-6649-a698-4ac3-11126745797f`, feed2: `ab5ed2cd-a79e-4f78-88f1-28318da857ef` },
        // { description: 'JRE26', id: 34, lat: 19.389649, lng: -99.135082, feed1: `${process.env.REACT_APP_STREAM_URL}/0568f8ad`, feed2: `${process.env.REACT_APP_STREAM_URL}/039b88dc` },
       /* { description: 'JRE27', id: 35, lat: 19.389979, lng: -99.135397, feed1: `${process.env.REACT_APP_STREAM_URL}/13d77cf2`, feed2: `${process.env.REACT_APP_STREAM_URL}/119fedc4` },
        { description: 'JRE28', id: 36, lat: 19.390227, lng: -99.135368, feed1: `${process.env.REACT_APP_STREAM_URL}/68f454e8`, feed2: `${process.env.REACT_APP_STREAM_URL}/db69e29a` },
        
        { description: 'JRE29', id: 39, lat: 19.379917, lng: -99.177756, feed1: `${process.env.REACT_APP_STREAM_URL}/d0b26012`, feed2: `${process.env.REACT_APP_STREAM_URL}/81bcac22` },
        { description: 'JRE30', id: 40, lat: 19.379890, lng: -99.177398, feed1: `${process.env.REACT_APP_STREAM_URL}/cfc0ffc2`, feed2: `${process.env.REACT_APP_STREAM_URL}/9756cb6b` },
        { description: 'JRE31', id: 41, lat: 19.380022, lng: -99.177900, feed1: `${process.env.REACT_APP_STREAM_URL}/dd6044ac`, feed2: `${process.env.REACT_APP_STREAM_URL}/ddcbe2fe` },
        { description: 'JRE32', id: 42, lat: 19.379603, lng: -99.178275, feed1: `${process.env.REACT_APP_STREAM_URL}/6b25c91a`, feed2: `${process.env.REACT_APP_STREAM_URL}/6697fe57` },
        { description: 'JRE33', id: 43, lat: 19.379694, lng: -99.177939, feed1: `${process.env.REACT_APP_STREAM_URL}/3e1a099a`, feed2: `${process.env.REACT_APP_STREAM_URL}/ba8184a2` }*/
      ],
      liveFeedUrl: '',
      showLiveFeed: false,
    };

    this.closeLiveFeed = this.closeLiveFeed.bind(this);
    this.showLiveFeed = this.showLiveFeed.bind(this);
  }

  closeLiveFeed = () => {
    this.setState({
      liveFeedUrl: '',
      showLiveFeed: false
    });
  }

  componentDidMount() { 
    this.setState({
      map: {
        center: {
          lat: 19.389491,
          lng: -99.161887
        },
        zoom: 14
      }
    })
  }

  showLiveFeed = (url) => {
   
    this.setState({
      liveFeedUrl: url,
      showLiveFeed: true
    });
  };

  render() {
    return (
      <React.Fragment>
        <Header 
          textAlign="center">
          <Image
            src={logo}
            style={{ width: '135px', height: '40px' }} />
        </Header>
        <Grid columns={1}>
          <Grid.Column width={16}>
            <div style={{ height: '89vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDrMZ0S9pZ9d3llJBBiwijRToDnzAzqG24' }}
                defaultCenter={this.state.map.center}
                defaultZoom={this.state.map.zoom}>
                  {this.state.markers.map(marker => {
                    return (
                      <Marker
                        key={marker.id}
                        lat={marker.lat}
                        lng={marker.lng}
                        marker={marker}
                        onLiveFeed={this.showLiveFeed} />
                    )
                  })}
              </GoogleMapReact>
              

              
              <Player 
                closeLiveFeed={this.closeLiveFeed}
                feedUrl={this.state.liveFeedUrl}
                showLiveFeed={this.state.showLiveFeed} />
              
            </div>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
