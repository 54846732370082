import React, { Component } from 'react';
import { Button, Card, Icon, Popup } from 'semantic-ui-react';

class Marker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLiveFeed: false,
            feedUrl: ''
        };
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    content={
                    <Card>
                        <Card.Content>
                            <Card.Header>{this.props.marker.description}</Card.Header>
                            <Card.Meta>{this.props.marker.lat.toString() + ', ' + this.props.marker.lng.toString()}</Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                            <Button 
                                basic color='green'
                                onClick={() => { this.props.onLiveFeed(this.props.marker.feed1) }}>
                                Live feed 1
                            </Button>
                            <Button 
                                basic color='green'
                                onClick={() => { this.props.onLiveFeed(this.props.marker.feed2) }}>
                                Live feed 2
                            </Button>
                        </Card.Content>
                    </Card>
                    }
                    hoverable={true}
                    key="test"
                    trigger={<Icon name="video camera" size="large" color="yellow" />} />
            </React.Fragment>
        )
    }
};

export default Marker;